<template>
  <div class="admin_share">
    <div class="title">邀请码</div>
    <div id="invite"></div>
    <div class="invite_dec">长按保存二维码</div>
  </div>
</template>
<script>
import QRcode from "qrcodejs2";
import { getInviteApi } from "api/AdminCenter";
export default {
  mounted() {
    getInviteApi().then(res => {
      if (res.code == 0) {
        const url = res.data.invite_code_url;
        new QRcode("invite", {
          text: url,
          width: 300, //图像宽度
          height: 300, //图像高度
          colorDark: "#673DCB",
          colorLight: "#ffffff",
          correctLevel: QRcode.CorrectLevel.H
        });
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.admin_share {
  
  .title {
    padding-bottom: 35px;
    font-size: 30px;
    color: #517cfc;
  }
  .invite_dec {
    padding-top: 15px;
    font-size: 18px;
    color: #517cfc;
  }
  padding: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
</style>